import { Control, Controller } from 'react-hook-form';
import React from 'react';
import { Loader, Select } from '@mantine/core';
import { useShepherdAdminUsersQuery } from '../../../graphql/operations/users.generated';
import { EMPTY_ARRAY } from '../../../constants';
import { isEmpty } from 'lodash';

const AssigneeId = ({
  control,
  name = 'assigneeId',
  label = 'Assignee',
}: {
  control: Control<any, any>;
  name?: string;
  label?: string;
}) => {
  const { data: dataShepherdAdminUsers, loading: loadingAdminUsers } = useShepherdAdminUsersQuery({
    variables: { activeOnly: true },
  });

  if (loadingAdminUsers) {
    return <Select leftSection={<Loader size="xs" />} label={label} required disabled />;
  }

  const validUserOptions =
    dataShepherdAdminUsers?.shepherdAdminUsers
      ?.filter((user) => !isEmpty(user.name))
      .map((user) => ({
        label: user.name,
        value: user.id,
      })) ?? EMPTY_ARRAY;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label={label}
          error={fieldState.error?.message}
          required={true}
          data={validUserOptions}
          searchable
        />
      )}
    />
  );
};

export default AssigneeId;
